import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Access from '@common/components/access';
import { TableDropdown } from '@common/components/table';
import ConfirmButton from '@common/components/confirm-button';
import { AlertService } from '@common/services/alert';

import { EUserStatus } from '@common/utils/user';
import { useAppSelector, useCanAccess } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import { EPermissions } from '@common/definitions';
import Api from '@common/services/api';
import { restoreUser } from '@modules/admin/actions';
import { ExtendedUser, NetworkScope } from '@common/types/objects';

import './styles.scss';

interface UserActionsProps {
  item: ExtendedUser;
  invitedAt?: string;
  loggedUser: ExtendedUser;
  onResendInvitation?: (item: ExtendedUser) => Promise<void>;
  onView?: (item: ExtendedUser) => void;
  onEdit?: (item: ExtendedUser) => void;
  onDelete?: (itemId: string) => void;
  userStatus: string;
  networks?: NetworkScope[];
}

const UserActions = ({
  item, loggedUser, userStatus, networks = [], invitedAt,
  onResendInvitation, onView, onEdit, onDelete,
}: UserActionsProps) => {
  const [isSending, setSending] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const org = useAppSelector(selected);
  const orgHasIntegration = !!org?.integration;

  const canUpdate = useCanAccess({
    permissions: EPermissions.ORGANISATION_USERS_UPDATE,
    networkPermissions: EPermissions.NETWORK_USERS_UPDATE,
    networks,
  });

  const handleResendInvitation = async () => {
    setSending(true);
    await onResendInvitation?.(item);
    setSending(false);
  };

  if (userStatus === EUserStatus.DELETED) {
    if (!canUpdate) return null;
    const onRestoreConfirmClick = async () => {
      try {
        await Api.post(`/v2/organisations/${org.id}/users/${item.id}/restore`);
        dispatch(restoreUser(item.id));
      } catch (error) {
        AlertService.error(t('common:something_went_wrong'));
        throw error;
      }
    };
    return (
      <TableDropdown id={`user-action-${item.id}`}>
        <ConfirmButton
          danger
          title={t('common:overview_content_confirm')}
          className="UserActions_Confirm"
          description={
            <>
              { t('organisation:user_restore_confirm_message') }
              {orgHasIntegration && (
                <span>
                  {t('organisation:user_restore_confirm_integration')}
                </span>
              )}
            </>
          }
          onConfirm={onRestoreConfirmClick}
        >
          <TableDropdown.Item>
            {t('organisation:restore')}
          </TableDropdown.Item>
        </ConfirmButton>
      </TableDropdown>
    );
  }

  return (
    <TableDropdown id={`user-action-${item.id}`} disabled={isSending}>
      {canUpdate && onEdit && (
        <TableDropdown.Item icon="settings" onClick={() => onEdit(item)}>
          {t('core:edit_profile_details')}
        </TableDropdown.Item>
      )}
      {onView && (
        <TableDropdown.Item icon="visibility" onClick={() => onView(item)}>
          {t('organisation:user_actions_view')}
        </TableDropdown.Item>
      )}
      <Access
        permissions={EPermissions.ORGANISATION_USERS_CREATE}
        networkPermissions={EPermissions.NETWORK_USERS_CREATE}
        networks={networks}
      >
        {!invitedAt && !item.last_login && onResendInvitation && (
          <TableDropdown.Item icon="email" onClick={handleResendInvitation}>
            {t('organisation:user_actions_send_invitation')}
          </TableDropdown.Item>
        )}
        {invitedAt && !item.last_login && onResendInvitation && (
          <TableDropdown.Item
            icon="email"
            onClick={!isSending ? handleResendInvitation : undefined}
            disabled={isSending}
          >
            {t('organisation:user_actions_resend_invitation')}
          </TableDropdown.Item>
        )}
      </Access>
      {item.id !== loggedUser.id && onDelete && (
        <Access
          permissions={EPermissions.ORGANISATION_USERS_REMOVE}
          networkPermissions={EPermissions.NETWORK_USERS_REMOVE}
          networks={networks}
        >
          <ConfirmButton
            danger
            title={t('organisation:user_actions_delete_confirm_title')}
            description={t('organisation:user_actions_delete_confirm_description')}
            onConfirm={() => onDelete(item.id)}
            confirmText={t('organisation:user_actions_delete_confirm_text')}
          >
            <TableDropdown.Item danger icon="delete__filled">
              {t('core:delete_user')}
            </TableDropdown.Item>
          </ConfirmButton>
        </Access>
      )}
    </TableDropdown>
  );
};

export default UserActions;

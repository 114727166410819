import React, { PropsWithChildren } from 'react';
import { UseCanAccessType, useCanAccess } from '@common/hooks/use-user-permissions';

interface IAccessProps extends PropsWithChildren<UseCanAccessType> {
  children: React.ReactNode;
  deniedComponent?: React.ReactNode;
}

const Access = ({ children, deniedComponent, ...props }: IAccessProps) => {
  const canAccess = useCanAccess(props);
  if (canAccess) return <>{children}</>;
  return (deniedComponent && <>{deniedComponent}</>) || null;
};

export default Access;
